import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Layout from '../../Layout/Layout'
import { StyledTour, Headline, Hint, ImageContainer, PlayButton, PlayButtonIcon } from './Tour.Styled'
import cover from '../../../assets/image/office/tour/cover.jpg'
import playButton from '../../../assets/image/office/tour/play.svg'

const Tour = ({ keyword }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout authRequired={false}>
      <StyledTour>
        <Container>
          <Headline>{translation.tour.headline}</Headline>
          <ImageContainer src={cover}>
            <PlayButton href="https://my.matterport.com/show/?m=6EjrXp8sdS4" target="_blank">
              <PlayButtonIcon src={playButton} />
            </PlayButton>
          </ImageContainer>
          <Hint>{translation.tour.hint}</Hint>
        </Container>
      </StyledTour>
    </Layout>
  )
}

export default Tour
