import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Headline, StyledTeam, Split, Side, Image, Text, CircleLink, Circle } from './Team.Styled'
import leadTeam from '../../../assets/image/home/team/lead-team.jpg'
import circle from '../../../assets/image/circle.png'

const Team = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledTeam>
      <Container>
        <Headline>{translation.home.team.headline}</Headline>
        <Split>
          <Side>
            <a href="/team">
              <Image src={leadTeam} />
            </a>
          </Side>
          <Side>
            <Text>
              <CircleLink href={'/stellen'}>
                <Circle src={circle} />
              </CircleLink>
              {translation.home.team.text}
            </Text>
          </Side>
        </Split>
      </Container>
    </StyledTeam>
  )
}

export default Team
