import {
  BackToHome,
  Navigation,
  BackIcon,
  StyledHeader,
  Phone,
  Toggle,
  CustomLink,
  Items,
  Item,
  ItemWrapper,
  Logo,
  MobileItems,
  AniversaryBanner,
} from './Header.Styled'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import logo from '../../../assets/image/logo.svg'
import { useNavigate } from 'react-router-dom'
import props from '../../../redux/props'
import GetIcon from '../../helper/GetIcon'
import Icon from '../../SubComponents/Icon/Icon'
import backIcon from '../../../assets/image/arrowToLeftBlue.svg'
import anniversaryBanner from '../../../assets/image/header/anniversary-banner.png'

const Header = ({ transparent = false }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const token = useSelector((s) => s[props.TOKEN])
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const goHome = () => {
    if (token) {
      navigate('/dashboard/home')
    } else {
      navigate('/')
    }
  }

  const isHomePage = () => {
    const path = window.location.pathname
    return path === '/'
  }

  return (
    <StyledHeader transparent={transparent}>
      <ItemWrapper>
        <Logo onClick={goHome} height={'60px'} src={logo} alt="" />
        {!isHomePage() && (
          <BackToHome onClick={goHome}>
            <BackIcon src={backIcon} />
            {translation.header.backToHome}
          </BackToHome>
        )}
        <AniversaryBanner src={anniversaryBanner} alt="" />
        <Items>
          {Object.keys(translation.header.menu)
            .slice(1, -2)
            .map((key) => (
              <Item key={key}>
                <CustomLink onClick={() => setOpen(false)} href={translation.header.menu[key].href}>
                  {translation.header.menu[key].name}
                </CustomLink>
              </Item>
            ))}
          <Phone href={`tel:${translation.genericValues.phone}`}>
            <Icon name="phone" height="30px" />
            {translation.genericValues.phone}
          </Phone>
        </Items>
        <Toggle onClick={() => setOpen(!open)}>
          <GetIcon name="bars" />
        </Toggle>
      </ItemWrapper>
      {open && (
        <Navigation>
          <MobileItems>
            {Object.keys(translation.header.menu)
              .slice(1, -2)
              .map((key) => (
                <Item key={key}>
                  <CustomLink onClick={() => setOpen(false)} href={translation.header.menu[key].href}>
                    {translation.header.menu[key].name}
                  </CustomLink>
                </Item>
              ))}
            <Phone href={`tel:${translation.genericValues.phone}`}>
              <Icon name="phone" height="30px" />
              {translation.genericValues.phone}
            </Phone>
          </MobileItems>
        </Navigation>
      )}
    </StyledHeader>
  )
}

export default Header
