import fetch, { METHOD } from '../fetch'

export const list = async (token, featureName, payload = {}) => {
  const { status, response } = await fetch(METHOD.GET, '/crud-interface/list', {
    token,
    featureName,
    payload,
  })
  if (status && response) {
    return response
  }
  return false
}

export const get = async (token, featureName, payload = {}) => {
  const { status, response } = await fetch(METHOD.GET, '/crud-interface/get', {
    token,
    featureName,
    payload,
  })
  if (status && response) {
    return response
  }
  return false
}

export const create = async (token, featureName, payload = {}) => {
  const { status, response } = await fetch(METHOD.POST, '/crud-interface/create', {
    token,
    featureName,
    payload,
  })
  if (status && response) {
    return response
  }
  return false
}

export const update = async (token, featureName, payload = {}) => {
  const { status, response } = await fetch(METHOD.PUT, '/crud-interface/update', {
    token,
    featureName,
    payload,
  })
  if (status && response) {
    return response
  }
  return false
}

export const remove = async (token, featureName, payload = {}) => {
  const { status, response } = await fetch(METHOD.DELETE, '/crud-interface/remove', {
    token,
    featureName,
    payload,
  })
  if (status) {
    return response
  }
  return false
}

export const upsert = async (token, featureName, payload = {}, matchProperties = [], query = {}) => {
  const { status, response } = await fetch(METHOD.PUT, '/crud-interface/upsert', {
    token,
    featureName,
    payload,
    matchProperties,
    query,
  })
  if (status && response) {
    return response
  }
  return false
}

export const multiUpsert = async (token, featureName, payload = [], matchProperties = [], query = {}) => {
  const { status, response } = await fetch(METHOD.PUT, '/crud-interface/multi-upsert', {
    token,
    featureName,
    payload,
    matchProperties,
    query,
  })
  if (status && response) {
    return response
  }
  return false
}
