import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Icon from '../../SubComponents/Icon/Icon'
import CustomForm from './CustomForm/CustomForm'

const { StyledContact, Headline, Info, Item, Value, FormWrapper } = require('./Contact.Styled')

const Contact = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledContact id="kontakt">
      <Container>
        <Headline>{translation.home.contact.headline}</Headline>
        <Info>
          <Item>
            <Icon height="25px" width="25px" name="phone" />
            <Value href={`tel:${translation.genericValues.phone}`}>{translation.genericValues.phone}</Value>
          </Item>
          <Item>
            <Icon height="25px" width="25px" name="mail" />
            <Value href={`mailto:${translation.genericValues.mail}`}>{translation.genericValues.mail}</Value>
          </Item>
          <Item>
            <Icon height="25px" width="25px" name="location" />
            <Value
              href={
                'https://www.google.com/maps/place/Zahnarztpraxis+Claudia+Eisberg/@51.6465556,7.2433085,17z/data=!4m13!1m7!3m6!1s0x47b8fd23377e1c5f:0xe29b2629261d70d!2sFritz-Reuter-Stra%C3%9Fe+38,+45739+Oer-Erkenschwick!3b1!8m2!3d51.6465556!4d7.2433085!3m4!1s0x47b8fd233efd36e7:0xc802821f8abe264a!8m2!3d51.6464465!4d7.2432826'
              }
              target={'_blank'}
            >{`${translation.genericValues.street} ${translation.genericValues.city}`}</Value>
          </Item>
        </Info>
        <FormWrapper>
          <CustomForm />
        </FormWrapper>
      </Container>
    </StyledContact>
  )
}

export default Contact
