import { StyledSlider, Arrow, Icon, Indicator } from './Slider.Styled'
import arrowLeft from '../../../assets/image/slider/arrow-left.svg'
import arrowRight from '../../../assets/image/slider/arrow-right.svg'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { getUUID } from '../../../utility'
import Slide from './Slide/Slide'

const Slider = ({ images }) => {
  const getArrow = (direction) => (clickHandler) => {
    return (
      <Arrow direction={direction} onClick={clickHandler}>
        <Icon src={direction === 'prev' ? arrowLeft : arrowRight} />
      </Arrow>
    )
  }

  const getIndicator = (clickHandler, isSelected, index, label) => {
    return <Indicator onClick={clickHandler} isSelected={isSelected} index={index} label={label} />
  }

  const getSliderElements = () => {
    return images.map((image, i) => <Slide key={getUUID()} image={image} />)
  }

  return (
    <StyledSlider>
      <Carousel
        infiniteLoop={true}
        interval={5000}
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={getArrow('prev')}
        renderArrowNext={getArrow('next')}
        renderIndicator={getIndicator}
      >
        {getSliderElements()}
      </Carousel>
    </StyledSlider>
  )
}

export default Slider
