import styled from 'styled-components'

export const StyledServices = styled.div`
  padding: 75px 0px;
`

export const Items = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`

export const Item = styled.a`
  background: rgb(50, 87, 154);
  background: linear-gradient(196deg, rgba(50, 87, 154, 1) 50%, rgba(103, 173, 204, 1) 100%);
  border-radius: 30px;
  padding: 20px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  text-decoration: none;
  gap: 20px;
  transition: transform 0.5s ease;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(50% - 12.5px);
  }
`

export const Icon = styled.img`
  width: ${({ width }) => `${width}%` || '100px'};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 100px;
  }
`

export const Name = styled.div`
  color: ${({ theme }) => theme.color.font.fifth};
  font-weight: bold;
  text-align: center;
  width: 100%;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
`
