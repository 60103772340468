import styled from 'styled-components'

export const StyledBackgroundWave = styled.div`
  z-index: 0;
  position: absolute;
  width: 100%;
  ${({ reverse }) => (reverse ? `bottom: 0` : `top: 0`)}
`

export const Spacer = styled.div`
  height: ${({ height }) => height};
  background-color: ${({ theme }) => theme.color.background.secondary};
`
