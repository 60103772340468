import styled from 'styled-components'

export const StyledDataProtection = styled.div`
  padding: 50px 0px;
`
export const Headline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
`
export const SubHeadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  margin-top: 15px;
`

export const ParagraphHeadline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  margin-top: 10px;
`

export const MinorHeadline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  margin-top: 10px;
`

export const Text = styled.div``
