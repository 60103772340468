import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import { StyledImprint, Headline, SubHeadline, Text } from './Imprint.Styled'

const Imprint = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout authRequired={false}>
      <StyledImprint>
        <Headline>{translation.imprint.headline}</Headline>
        <Container>
          <SubHeadline>Angaben gem&auml;&szlig; &sect; 5 TMG</SubHeadline>
          <Text>
            Claudia Eisberg
            <br />
            Zahnarztpraxis Claudia Eisberg
            <br />
            Fritz-Reuter-Str. 38
            <br />
            45739 Oer-Erkenschwick
          </Text>

          <SubHeadline>Kontakt</SubHeadline>
          <Text>
            Telefon: 02368/1516
            <br />
            E-Mail: praxis.eisberg@t-online.de
          </Text>

          <SubHeadline>Berufsbezeichnung und berufsrechtliche Regelungen</SubHeadline>
          <Text>
            Berufsbezeichnung:
            <br />
            Zahnarzt
          </Text>
          <SubHeadline>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</SubHeadline>
          <SubHeadline>Redaktionell verantwortlich</SubHeadline>
          <Text>Claudia Eisberg</Text>

          <SubHeadline>EU-Streitschlichtung</SubHeadline>
          <Text>
            Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{' '}
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </Text>

          <SubHeadline>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</SubHeadline>
          <Text>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</Text>
        </Container>
      </StyledImprint>
    </Layout>
  )
}

export default Imprint
