import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
const { StyledInstagram, Headline, Handle } = require('./Instagram.Styled')

const Instagram = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <StyledInstagram>
      <Container>
        <Headline>{translation.home.instagram.headline}</Headline>
        <Handle href={translation.genericValues.instagramLink} target="_blank">
          {translation.genericValues.instagramHandle}
        </Handle>
      </Container>
    </StyledInstagram>
  )
}

export default Instagram
