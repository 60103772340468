import styled from 'styled-components'

export const StyledTour = styled.div`
  padding: 100px 0px;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  text-transform: uppercase;
`

export const SliderWrapper = styled.div`
  margin-top: 100px;
`

export const Hint = styled.div`
  margin: 50px auto 0px;
  width: fit-content;
  padding: 25px 50px;
  background-color: ${({ theme }) => theme.color.background.hint};
  border-radius: 25px;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const ImageContainer = styled.div`
  margin-top: 50px;
  border-radius: 50px;
  height: 500px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  width: 50vw;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 300px;
    width: 100%;
  }
`

export const PlayButton = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const PlayButtonIcon = styled.img`
  width: 100px;
`
