import { StyledSlide, Image } from './Slide.Styled'

const Slide = ({ image }) => {
  return (
    <StyledSlide>
      <Image image={image} />
    </StyledSlide>
  )
}

export default Slide
