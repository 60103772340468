import mail from '../../../assets/image/mail.svg'
import phone from '../../../assets/image/phone.svg'
import location from '../../../assets/image/location.svg'
import instagram from '../../../assets/image/instagram.svg'
import facebook from '../../../assets/image/facebook.svg'
import arrowToRightBlue from '../../../assets/image/arrowToRightBlue.svg'
import arrowToRightGreen from '../../../assets/image/arrowToRightGreen.svg'
import { StyledIcon } from './Icon.Styled'

const Icon = ({ width, height, name }) => {
  switch (name) {
    case 'mail':
      return <StyledIcon src={mail} width={width} height={height} />
    case 'phone':
      return <StyledIcon src={phone} width={width} height={height} />
    case 'location':
      return <StyledIcon src={location} width={width} height={height} />
    case 'instagram':
      return <StyledIcon src={instagram} width={width} height={height} />
    case 'facebook':
      return <StyledIcon src={facebook} width={width} height={height} />
    case 'arrow-to-right-blue':
      return <StyledIcon src={arrowToRightBlue} width={width} height={height} />
    case 'arrow-to-right-green':
      return <StyledIcon src={arrowToRightGreen} width={width} height={height} />
    default:
      return ''
  }
}

export default Icon
