const props = {
  TOKEN: 'TOKEN',
  ACCOUNT: 'ACCOUNT',
  CURRENTLANG: 'CURRENTLANG',
  TRANSLATION: 'TRANSLATION',

  SELECTED_PROJECT: 'SELECTED_PROJECT',
}

export default props
