import styled from 'styled-components'

export const StyledServicesSubpage = styled.div`
  padding: 75px 0px;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xxlg};
  color: ${({ theme }) => theme.color.font.fifth};
  font-family: 'bold';
  text-align: center;
`

export const IconWrapper = styled.div`
  text-align: center;
  margin-top: 50px;
`

export const Icon = styled.img`
  max-width: 250px;
  max-height: 250px;
`

export const Boxes = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 50px;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const Box = styled.div`
  background: ${({ theme }) => theme.color.background.secondary};
  border-radius: 30px;
  padding: 20px;
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
`

export const Text = styled.div`
  margin-top: 15px;
  color: ${({ theme }) => theme.color.font.secondary};
`
