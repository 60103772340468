import styled from 'styled-components'

export const StyledContact = styled.div`
  padding: 75px 0px;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
`

export const Info = styled.div`
  margin-top: 50px;
`
export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 0px;
`
export const Value = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.fifth};
`

export const FormWrapper = styled.div`
  width: 70%;
  margin-left: 30%;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    margin-left: 0px;
  }
`
