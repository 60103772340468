import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCubes,
  faUserLock,
  faGear,
  faTrash,
  faBars,
  faCalendar,
  faFilter,
  faSort,
  faCheck,
  faHouseUser,
  faPlus,
  faBell,
  faBellSlash,
} from '@fortawesome/free-solid-svg-icons'

const GetIcon = ({ name }) => {
  switch (name) {
    case 'cubes':
      return <FontAwesomeIcon className="icon" icon={faCubes} />
    case 'user-lock':
      return <FontAwesomeIcon className="icon" icon={faUserLock} />
    case 'gear':
      return <FontAwesomeIcon className="icon" icon={faGear} />
    case 'trash':
      return <FontAwesomeIcon className="icon" icon={faTrash} />
    case 'bars':
      return <FontAwesomeIcon className="icon" icon={faBars} />
    case 'calendar':
      return <FontAwesomeIcon className="icon" icon={faCalendar} />
    case 'sort':
      return <FontAwesomeIcon className="icon" icon={faSort} />
    case 'filter':
      return <FontAwesomeIcon className="icon" icon={faFilter} />
    case 'check':
      return <FontAwesomeIcon className="icon" icon={faCheck} />
    case 'house-user':
      return <FontAwesomeIcon className="icon" icon={faHouseUser} />
    case 'plus':
      return <FontAwesomeIcon className="icon" icon={faPlus} />
    case 'bell':
      return <FontAwesomeIcon className="icon" icon={faBell} />
    case 'bell-slash':
      return <FontAwesomeIcon className="icon" icon={faBellSlash} />
    default:
      return <></>
  }
}

export default GetIcon
