import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import {
  CustomContainer,
  StyledJobs,
  Headline,
  Title,
  AboutText,
  Split,
  Page,
  Buttetpoints,
  BulletpointsTitle,
  Points,
  Point,
  TitleWrapper,
  OfferingImageSectionHeadline,
  OfferingImageSection,
  OfferingImageItem,
  OfferingImageItemImage,
  OfferingImageItemText,
} from './Jobs.Styled'
import modernes_arbeitsumfeld from '../../assets/image/jobs/modernes_arbeitsumfeld.jpg'
import flexible_arbeitszeiten from '../../assets/image/jobs/flexible_arbeitszeiten.jpg'
import aufgeschlossenes_team from '../../assets/image/jobs/aufgeschlossenes_team.jpg'
import BackgroundWave from '../SubComponents/BackgroundWave/BackgroundWave'
import { getUUID } from '../../utility'
import ApplicationForm from './ApplicationForm/ApplicationForm'

const Jobs = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const offeringImages = [modernes_arbeitsumfeld, aufgeschlossenes_team, flexible_arbeitszeiten]

  return (
    <Layout authRequired={false}>
      <StyledJobs>
        <BackgroundWave height={'50vh'} />
        <CustomContainer>
          <Headline>{translation.jobs.headline}</Headline>
          <TitleWrapper>
            <Title>{translation.jobs.title}</Title>
          </TitleWrapper>
          <AboutText>{translation.jobs.aboutText}</AboutText>
          <Split>
            <Page>
              <Buttetpoints>
                <BulletpointsTitle>{translation.jobs.offeringsHeadline}</BulletpointsTitle>
                <Points>
                  {translation.jobs.offerings.map((o) => (
                    <Point key={getUUID()}>{o}</Point>
                  ))}
                </Points>
              </Buttetpoints>
            </Page>
            <Page>
              <Buttetpoints>
                <BulletpointsTitle>{translation.jobs.expectationsHeadline}</BulletpointsTitle>
                <Points>
                  {translation.jobs.expectations.map((o) => (
                    <Point key={getUUID()}>{o}</Point>
                  ))}
                </Points>
              </Buttetpoints>
            </Page>
          </Split>
          <OfferingImageSectionHeadline>{translation.jobs.offering.headline}</OfferingImageSectionHeadline>
          <OfferingImageSection>
            {translation.jobs.offering.items.map((item, i) => (
              <OfferingImageItem key={getUUID()}>
                <OfferingImageItemImage image={offeringImages[i]} />
                <OfferingImageItemText>{item}</OfferingImageItemText>
              </OfferingImageItem>
            ))}
          </OfferingImageSection>
          <ApplicationForm />
        </CustomContainer>
      </StyledJobs>
    </Layout>
  )
}

export default Jobs
