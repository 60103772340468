import styled from 'styled-components'

export const StyledSlide = styled.div`
  text-align: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    max-width: 600px;
    height: 350px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const Image = styled.div`
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: cover;
  border-radius: 50px;
  width: calc(100% - 150px) !important;
  margin: 0 75px 75px 75px;
  height: 600px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: calc(100% - 50px);
    width: calc(100% - 75px) !important;
    margin: 0 0 50px 0;
    margin-left: auto;
    margin-right: auto;
  }
`
