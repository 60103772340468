import styled from 'styled-components'

export const Split = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 15px;
  }
`
export const Page = styled.div`
  width: 50%;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const CustomTextareaStyle = styled.textarea`
  border: none;
  color: ${({ theme }) => theme.color.font.secondary};
  background-color: ${({ theme }) => theme.color.background.secondary};
  padding: 15px 25px;
  font-size: ${({ theme }) => theme.font.size.lg};
  border-radius: 30px;
  display: block;
  width: 100%;
  outline: none;
  resize: none;
  box-sizing: border-box;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.font.secondary};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.font.secondary};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.font.secondary};
  }
`

export const CustomInputStyle = styled.input`
  border: none;
  color: ${({ theme }) => theme.color.font.secondary};
  padding: 15px 25px;
  display: block;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.color.background.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  box-shadow: -1px 1px 8px -2px ${({ theme }) => theme.shadow};
  -webkit-box-shadow: -1px 1px 8px -2px ${({ theme }) => theme.shadow};
  -moz-box-shadow: -1px 1px 8px -2px ${({ theme }) => theme.shadow};

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.font.secondary};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.font.secondary};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.font.secondary};
  }
`

export const CustomButtonStyle = styled.button`
  margin-left: auto;
  color: ${({ theme }) => theme.color.font.secondary};
  background-color: ${({ theme }) => theme.color.background.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  padding: 10px 25px 15px;
  display: inline-block;
  text-align: center;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
`

export const Message = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
`
