import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import { StyledTeam, Headline, Members, Member, Info, Name, Position } from './Team.Styled'
import img1 from '../../assets/image/team/1.jpg'
import img2 from '../../assets/image/team/2.jpg'
import img3 from '../../assets/image/team/3.jpg'
import img4 from '../../assets/image/team/4.jpg'
import img5 from '../../assets/image/team/5.jpg'
import img6 from '../../assets/image/team/6.jpg'
import img8 from '../../assets/image/team/8.jpg'
import img9 from '../../assets/image/team/9.jpg'
import { getUUID } from '../../utility'
import BackgroundWave from '../SubComponents/BackgroundWave/BackgroundWave'

const Team = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const images = {
    lead: [img1, img2],
    members: [img3, img4, img5, img6, img8, img9],
  }

  const getMembers = (listKey) => {
    return translation.team[listKey].map((m, i) => (
      <Member image={images[listKey][i]} key={getUUID()}>
        <Info>
          {m.name && <Name>{m.name}</Name>}
          {m.position && <Position>{m.position}</Position>}
        </Info>
      </Member>
    ))
  }

  return (
    <Layout authRequired={false}>
      <StyledTeam>
        <BackgroundWave height={'50vh'} />
        <Container>
          <Headline>{translation.team.headline}</Headline>
          <Members>{getMembers('lead')}</Members>
          <Members>{getMembers('members')}</Members>
        </Container>
      </StyledTeam>
    </Layout>
  )
}

export default Team
