import styled from 'styled-components'

export const StyledTeam = styled.div`
  padding: 75px 0px;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
`

export const Split = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  align-items: flex-end;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const Side = styled.div`
  width: 50%;
  &:first-child {
    width: 70%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    &:first-child {
      width: 100%;
    }
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 25px;
`

export const Text = styled.div`
  position: relative;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const CircleLink = styled.a`
  position: absolute;
  top: -150px;
  right: -125px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`
export const Circle = styled.img`
  width: 200px;
`
