import Layout from '../Layout/Layout'
import { StyledHome } from './Home.Styled'
import Wave from '../SubComponents/Wave/Wave'
import Hero from './Hero/Hero'
import Services from './Services/Services'
import Instagram from './Instagram/Instagram'
import Team from './Team/Team'
import Office from './Office/Office'
import Contact from './Contact/Contact'

const Home = () => {
  return (
    <Layout authRequired={false}>
      <StyledHome>
        <Hero />
        <Wave />
        <Services />
        <Wave reverse />
        <Instagram />
        <Wave />
        <Team />
        <Wave reverse />
        <Office />
        <Wave />
        <Contact />
      </StyledHome>
    </Layout>
  )
}

export default Home
