import styled from 'styled-components'

export const StyledGalleryPage = styled.div`
  padding: 100px 0px;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  text-transform: uppercase;
`

export const SliderWrapper = styled.div`
  margin-top: 100px;
`

export const Hint = styled.div`
  margin: 75px auto 0px;
  width: fit-content;
  padding: 25px 50px;
  background-color: ${({ theme }) => theme.color.background.hint};
  border-radius: 25px;
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 25px;
  }
`
