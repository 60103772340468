import { Form } from '@think-internet/ui-components'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { lambdaCall, LAMBDA_ENDPOINTS } from '../../../../fetch'
import props from '../../../../redux/props'
import { CustomTextareaStyle, Split, Page, CustomInputStyle, CustomButtonStyle, Message } from './CustomForm.Styled'

const CustomInput = ({ type, value, onChange, placeholder, required = false }) => {
  return (
    <CustomInputStyle
      onChange={(e) => onChange(e.target.value)}
      type={type || 'text'}
      value={value || ''}
      placeholder={placeholder}
      required={required}
    />
  )
}

const CustomTextarea = ({ value, onChange, placeholder, required = false }) => {
  return <CustomTextareaStyle onChange={(e) => onChange(e.target.value)} value={value || ''} placeholder={placeholder} required={required} />
}

const CustomButton = ({ text, onClick }) => {
  return <CustomButtonStyle onClick={onClick}>{text}</CustomButtonStyle>
}

export const CustomForm = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState({ type: 'contact' })
  const [message, setMessage] = useState(null)

  const updateProperty = (key) => (value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const submit = async () => {
    const result = await lambdaCall(LAMBDA_ENDPOINTS.SEND_MAIL, data)
    if (result.status === true) {
      setData({ type: 'contact' })
      setMessage(translation.home.contact.form.success)
    } else {
      setMessage(translation.home.contact.form.error)
    }
  }

  return (
    <Form onSubmit={submit}>
      <Split>
        <Page>
          <CustomTextarea value={data.message} onChange={updateProperty('message')} placeholder={translation.generic.message} required />
        </Page>
        <Page>
          <CustomInput required value={data.name} onChange={updateProperty('name')} placeholder={translation.generic.name} />
          <CustomInput required type="email" value={data.mail} onChange={updateProperty('mail')} placeholder={translation.generic.mail} />
          <CustomInput required value={data.subject} onChange={updateProperty('subject')} placeholder={translation.generic.subject} />
          {message && <Message>{message}</Message>}
          <CustomButton type="submit" text={translation.home.contact.form.submit} />
        </Page>
      </Split>
    </Form>
  )
}

export default CustomForm
