import {
  StyledHero,
  Split,
  Side,
  Image,
  Times,
  TimeColumn,
  Time,
  MailCTA,
  Text,
  MailCTAWrapper,
  Banner,
  Circle,
  CircleLink,
  TimesHeadline,
  Hint,
  SocialSplit,
  SocialIconWrapper,
  StyledLink,
  AniversaryBanner,
} from './Hero.Styled'
import Icon from '../../SubComponents/Icon/Icon'
import leadTeam from '../../../assets/image/home/hero/lead-team.jpg'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Container } from '@think-internet/ui-components'
import { getUUID } from '../../../utility'
import circle from '../../../assets/image/circle_white.png'
import anniversaryBanner from '../../../assets/image/header/anniversary-banner.png'

const Link = ({ href, children, newPage = false }) => {
  return (
    <StyledLink href={href} target={newPage ? '_blank' : ''}>
      {children}
    </StyledLink>
  )
}

const Hero = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledHero>
      <Container>
        <Split>
          <Side>
            <Image src={leadTeam} />
            <Banner href="/stellen">
              {translation.home.hero.banner.text}
              <Icon width={'15px'} name="arrow-to-right-green" />
              <CircleLink href={'/stellen'}>
                <Circle src={circle} />
              </CircleLink>
            </Banner>
          </Side>
          <AniversaryBanner src={anniversaryBanner} alt="" />
          <Side>
            <SocialSplit>
              <SocialIconWrapper>
                <Icon height={'25px'} name={'instagram'} />
              </SocialIconWrapper>
              <Link newPage href={translation.genericValues.instagramLink}>
                {translation.genericValues.instagramHandle}
              </Link>
            </SocialSplit>
            <SocialSplit>
              <SocialIconWrapper>
                <Icon height={'25px'} name={'facebook'} />
              </SocialIconWrapper>
              <Link newPage href={translation.genericValues.facebookLink}>
                {translation.genericValues.facebookHandle}
              </Link>
            </SocialSplit>
            <TimesHeadline>{translation.home.hero.timesHeadline}</TimesHeadline>
            <Times>
              {translation.home.hero.timesColumns.map((c) => (
                <TimeColumn key={getUUID()}>
                  {c.map((t) => (
                    <Time key={getUUID()}>{t ? t : '\u00A0'}</Time>
                  ))}
                </TimeColumn>
              ))}
            </Times>
            <Hint>{translation.home.hero.timeHint}</Hint>
            <MailCTAWrapper>
              <MailCTA href={`mailto:${translation.genericValues.mail}`}>
                <Icon width={'30px'} name="mail" />
                <Text>{translation.genericValues.mail}</Text>
              </MailCTA>
            </MailCTAWrapper>
          </Side>
        </Split>
      </Container>
    </StyledHero>
  )
}

export default Hero
