import styled from 'styled-components'
import { Link } from '@think-internet/ui-components'
import bg from '../../../assets/image/footer/bg.svg'
import Wave from '../../SubComponents/Wave/Wave'

export const StyledFooter = styled.div``

export const MobileWave = styled(Wave)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const Content = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  padding: 250px 50px 50px;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-color: ${({ theme }) => theme.color.background.secondary};
    background-image: none;
    padding: 50px;
  }
`

export const Column = styled.div`
  flex: 1 1 0px;
`

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.md};
`

export const CustomText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.lightblack};
`

export const Flex = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const Header = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    ${({ hideOnMobile }) =>
      hideOnMobile &&
      `
      display: none;
      `}
  }
`

export const ColumnContent = styled.div`
  margin-top: 25px;
  color: ${({ theme }) => theme.color.font.thirdary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    color: ${({ theme }) => theme.color.font.secondary};
  }
`

export const LineSplit = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: ${({ align }) => align || 'flex-start'};
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.color.font.thirdary};
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    color: ${({ theme }) => theme.color.font.secondary};
  }
`

export const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
