import styled from 'styled-components'

export const StyledHeader = styled.div`
  position: absolute;
  width: 100vw;
  height: ${({ theme }) => theme.headerHeight};
  z-index: 9999;
  top: 0;
  background-color: ${({ transparent, theme }) => (transparent ? theme.color.background.secondary : theme.color.background.secondary)};
`

export const ItemWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Items = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`

export const MobileItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Item = styled.div`
  margin: 0px 15px;
  color: ${({ theme }) => theme.color.font.primary};
  display: flex;
  align-items: center;
  &:last-child {
    text-align: right;
  }
`

export const Toggle = styled.div`
  cursor: pointer;
  margin-right: 15px;
  color: ${({ theme }) => theme.color.font.secondary};
  .icon {
    font-size: 30px;
  }
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const Navigation = styled.div`
  padding: 15px 0px;
  background-color: ${({ theme }) => theme.color.background.primary};
  position: absolute;
  width: 100vw;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  margin-top: ${({ theme }) => theme.headerHeight};
  top: 0px;
  left: 0px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-content: flex-start;
`

export const CustomLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  transition: all 0.25s ease-in-out;
  text-decoration: none;
  ${({ onClick }) => !onClick && 'cursor: default'};
  cursor: pointer;
  ${({ bold }) =>
    bold &&
    `
    font-family: 'bold';
  `};
  &:hover {
    color: ${({ theme }) => theme.color.background.sixth};
  }
`

export const Logo = styled.img`
  cursor: pointer;
  height: 50px;
  margin-left: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-left: 15px;
  }
`

export const Phone = styled.a`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: ${({ theme }) => theme.font.size.lg};
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.fourth};
  background-color: ${({ theme }) => theme.color.background.fifth};
  padding: 10px 35px 10px 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-left: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-left: 15px;
    border-radius: 15px;
  }
`

export const BackToHome = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 20px;
  position: absolute;
  left: 30px;
  top: ${({ theme }) => `calc(${theme.headerHeight} + 15px)`};
  cursor: pointer;
`

export const BackIcon = styled.img`
  height: 30px;
  width: 30px;
  margin-top: 4px;
`

export const AniversaryBanner = styled.img`
  height: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: none;
  }
`
