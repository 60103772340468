import styled from 'styled-components'

export const StyledInstagram = styled.div`
  background-color: ${({ theme }) => theme.color.background.secondary};
  padding: 75px 0px;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
`

export const Handle = styled.a`
  color: ${({ theme }) => theme.color.font.fifth};
  display: block;
  text-decoration: none;
  margin-top: 15px;
`
