import equipment1 from '../../assets/image/office/equipment/1.jpg'
import equipment2 from '../../assets/image/office/equipment/2.jpg'
import office1 from '../../assets/image/office/office/1.jpg'
import office2 from '../../assets/image/office/office/2.jpg'

const images = {
  equipment: [equipment1, equipment2],
  office: [office1, office2],
}

export default images
