import styled from 'styled-components'

export const StyledOffice = styled.div`
  background-color: ${({ theme }) => theme.color.background.secondary};
  padding: 75px 0px;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
`

export const HorizontalSplit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  margin-top: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 25px;
  }
`
export const HorizontalItem = styled.a`
  width: 50%;
  height: 500px;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center;
  border-radius: 50px;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 250px;
    width: 100%;
  }
`

export const HorizontalItemNoLink = styled.div`
  width: 50%;
  height: 500px;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center;
  border-radius: 50px;
  display: flex;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const VerticalSplit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
  height: 100%;
  width: 100%;
`
export const VerticalItem = styled.a`
  flex: 1;
  width: 100%;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center;
  border-radius: 50px;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
`

export const Name = styled.div`
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: 'bold';
  font-size: ${({ theme }) => theme.font.size.lg};
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;
  width: 100%;
`
