import styled from 'styled-components'

export const StyledHero = styled.div`
  background-color: ${({ theme }) => theme.color.background.secondary};
  padding: 75px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`

export const Split = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 25px;
  }
`

export const Side = styled.div`
  width: 50%;
  position: relative;
  &:first-child {
    width: 80%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    &:first-child {
      width: 100%;
    }
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 25px;
`

export const Times = styled.div`
  display: flex;
  gap: 10px;
`
export const TimeColumn = styled.div``
export const Time = styled.div`
  color: ${({ theme }) => theme.color.font.fifth};
  font-size: ${({ theme }) => theme.font.size.lg};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
  }
`

export const MailCTAWrapper = styled.div`
  margin-top: 25px;
`

export const MailCTA = styled.a`
  color: ${({ theme }) => theme.color.font.fourth};
  background-color: ${({ theme }) => theme.color.background.fifth};
  text-decoration: none;
  padding: 15px 20px 20px;
  border-radius: 15px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  right: 0px;
  box-sizing: content-box;
`

export const Text = styled.span``

export const Banner = styled.a`
  background-color: ${({ theme }) => theme.color.background.darkBlue};
  color: ${({ theme }) => theme.color.font.sixth};
  border-radius: 15px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 15px 20px 20px;
  position: absolute;
  bottom: 0px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    border-radius: 15px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`

export const CircleLink = styled.div`
  position: absolute;
  top: -60px;
  right: -50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`
export const Circle = styled.img`
  width: 100px;
`

export const TimesHeadline = styled.div`
  margin-top: 15px;
  color: ${({ theme }) => theme.color.font.fifth};
  font-size: ${({ theme }) => theme.font.size.lg};
  font-family: 'bold';
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
  }
`

export const Hint = styled.div`
  color: ${({ theme }) => theme.color.font.fifth};
  margin-top: 10px;
  /* font-size: ${({ theme }) => theme.font.size.lg}; */
`

export const SocialSplit = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: ${({ align }) => align || 'flex-start'};
`

export const SocialIconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.color.font.fifth};
  text-decoration: none;
`

export const AniversaryBanner = styled.img`
  width: 100%;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`
