import Layout from '../Layout/Layout'
import { StyledServicesSubpage, Icon, Boxes, Box, Headline, Title, Text, IconWrapper } from './ServicesSubpage.Styled'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import { getUUID } from '../../utility'
import { Container } from '@think-internet/ui-components'
import prophylaxe from '../../assets/image/services/prophylaxe.svg'
import prothetik from '../../assets/image/services/prothetik.svg'
import implantologie from '../../assets/image/services/implantologie.svg'
import aestethischeZahnmedizin from '../../assets/image/services/aestethischeZahnmedizin.svg'

const ServicesSubpage = ({ item }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const icons = {
    prophylaxe,
    prothetik,
    implantologie,
    aestethischeZahnmedizin,
  }

  return (
    <Layout authRequired={false} headerTransparent={true}>
      <StyledServicesSubpage>
        <Container>
          <Headline>{translation.services[item].headline}</Headline>
          <IconWrapper>
            <Icon src={icons[item]} />
          </IconWrapper>
          <Boxes>
            {translation.services[item].boxes.map((box) => (
              <Box key={getUUID()}>
                <Title>{box.title}</Title>
                {box.text.map((t) => (
                  <Text>{t}</Text>
                ))}
              </Box>
            ))}
          </Boxes>
        </Container>
      </StyledServicesSubpage>
    </Layout>
  )
}

export default ServicesSubpage
