import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Headline, Icon, Item, Items, Name, StyledServices } from './Services.Styled'
import prothetik from '../../../assets/image/services/prothetik.svg'
import implantologie from '../../../assets/image/services/implantologie.svg'
import aestethischeZahnmedizin from '../../../assets/image/services/aestethischeZahnmedizin.svg'
import prophylaxe from '../../../assets/image/services/prophylaxe.svg'

const Services = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const icons = [prothetik, prophylaxe, aestethischeZahnmedizin, implantologie]
  const widths = [70, 90, 100, 60]

  return (
    <StyledServices id="leistungen">
      <Container>
        <Headline>{translation.home.services.headline}</Headline>
        <Items>
          {translation.home.services.items.map((item, index) => (
            <Item key={item.name} href={item.href}>
              <Name>{item.name}</Name>
              <Icon width={widths[index]} src={icons[index]} />
            </Item>
          ))}
        </Items>
      </Container>
    </StyledServices>
  )
}

export default Services
