import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import tour from '../../../assets/image/home/office/tour.jpg'
import equipment from '../../../assets/image/home/office/equipment.jpg'
import furnishing from '../../../assets/image/home/office/furnishing.jpg'
import { StyledOffice, Headline, HorizontalSplit, HorizontalItem, HorizontalItemNoLink, VerticalSplit, VerticalItem, Name } from './Office.Styled'

const Office = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <StyledOffice id="praxis">
      <Container>
        <Headline>{translation.home.office.headline}</Headline>
        <HorizontalSplit>
          <HorizontalItemNoLink>
            <VerticalSplit>
              <VerticalItem bg={equipment} href={'/ausstattung'}>
                <Name>{translation.home.office.equipment}</Name>
              </VerticalItem>
              <VerticalItem bg={furnishing} href={'/einrichtung'}>
                <Name>{translation.home.office.furnishing}</Name>
              </VerticalItem>
            </VerticalSplit>
          </HorizontalItemNoLink>
          <HorizontalItem bg={tour} href={'/rundgang'}>
            <Name>{translation.home.office.tour}</Name>
          </HorizontalItem>
        </HorizontalSplit>
      </Container>
    </StyledOffice>
  )
}

export default Office
