import styled from 'styled-components'

export const StyledTeam = styled.div`
  padding: 75px 0px;
  z-index: 1;
  position: relative;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xxlg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  z-index: 1;
  position: relative;
`

export const Members = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 50px;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`

export const Member = styled.div`
  background: ${({ theme }) => theme.color.background.secondary};
  background-image: url(${({ image }) => image});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px;
  width: calc(33.3% - 20px);
  position: relative;
  transition: transform 0.5s ease;
  &:hover {
    transform: scale(1.05);
  }
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const Info = styled.div`
  position: absolute;
  bottom: 25px;
  width: 100%;
  text-align: center;
`

export const Name = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: 'bold';
`

export const Position = styled.div`
  color: ${({ theme }) => theme.color.font.thirdary};
  font-size: ${({ theme }) => theme.font.size.lg};
`
