import { useState } from 'react'
import { useSelector } from 'react-redux'
import { lambdaCall, LAMBDA_ENDPOINTS, s3Upload } from '../../../fetch'
import props from '../../../redux/props'
import {
  StyledApplicationForm,
  Headline,
  CustomTextareaStyle,
  Split,
  Page,
  CustomInputStyle,
  CustomButtonStyle,
  FileInput,
  FileLabel,
  FileName,
  CVWrapper,
  Message,
} from './ApplicationForm.Styled'

const CustomInput = ({ type, value, onChange, placeholder, required = false }) => {
  return (
    <CustomInputStyle
      onChange={(e) => onChange(e.target.value)}
      type={type || 'text'}
      value={value || ''}
      placeholder={placeholder}
      required={required}
    />
  )
}

const CustomTextarea = ({ value, onChange, placeholder, required = false }) => {
  return <CustomTextareaStyle onChange={(e) => onChange(e.target.value)} value={value || ''} placeholder={placeholder} required={required} />
}

const CustomButton = ({ text, onClick }) => {
  return <CustomButtonStyle onClick={onClick}>{text}</CustomButtonStyle>
}

export const ApplicationForm = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState({ type: 'job-application' })
  const [message, setMessage] = useState(null)

  const updateProperty = (key) => (value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const submit = async () => {
    if (data.cv?.[0]) {
      const { name, size } = data.cv[0]
      if (size < 50000000) {
        const result = await lambdaCall(LAMBDA_ENDPOINTS.GET_PRESIGNED_UPLOAD_URL, { name, size })
        if (result.uploadURL && result.key) {
          const { uploadURL, key } = result
          const uploadStatus = await s3Upload(uploadURL, data.cv[0])
          if (uploadStatus) {
            const result = await lambdaCall(LAMBDA_ENDPOINTS.SEND_MAIL, { ...data, cvKey: key, cvName: name })
            if (result.status === true) {
              setData({ type: 'job-application' })
              setMessage(translation.jobs.form.success)
            } else {
              setMessage(translation.jobs.form.error)
            }
          } else {
            setMessage(translation.jobs.form.error)
          }
        } else {
          setMessage(translation.jobs.form.error)
        }
      } else {
        setMessage(translation.jobs.form.max_size_error)
      }
    } else {
      setMessage(translation.jobs.form.cv_error)
    }
  }

  return (
    <StyledApplicationForm onSubmit={submit}>
      <Headline>{translation.jobs.form.headline}</Headline>
      <Split>
        <Page>
          <CustomInput required value={data.name} onChange={updateProperty('name')} placeholder={translation.generic.name} />
          <CustomInput required type="email" value={data.mail} onChange={updateProperty('mail')} placeholder={translation.generic.mail} />
          <CustomInput required value={data.subject} onChange={updateProperty('subject')} placeholder={translation.generic.subject} />
          <CustomTextarea value={data.message} onChange={updateProperty('message')} placeholder={translation.jobs.form.messagePlaceholder} required />
        </Page>
        <Page width="20%">
          <CVWrapper>
            <FileName>{data.cv && data.cv[0].name}</FileName>
            <FileLabel htmlFor="cv">{translation.jobs.form.cv}</FileLabel>
            <FileInput
              multiple={false}
              id="cv"
              type="file"
              accept="application/pdf"
              onChange={(e) => updateProperty('cv')(e.target.files)}
              placeholder={translation.generic.name}
            />
          </CVWrapper>
        </Page>
        <Page>
          <CustomButton type="submit" text={translation.home.contact.form.submit} />
        </Page>
      </Split>
      {message && <Message>{message}</Message>}
    </StyledApplicationForm>
  )
}

export default ApplicationForm
