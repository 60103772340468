import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import Home from './components/Home/Home.js'
import Jobs from './components/Jobs/Jobs.js'
import ServicesSubpage from './components/ServicesSubpage/ServicesSubpage.js'
import Team from './components/Team/Team.js'
import GalleryPage from './components/Office/GalleryPage/GalleryPage.js'
import Tour from './components/Office/Tour/Tour.js'
import Imprint from './components/Imprint/Imprint.js'
import DataProtection from './components/DataProtection/DataProtection.js'
import './global.scss'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path={'team'} exact={true} element={<Team />} />
            <Route path={'stellen'} exact={true} element={<Jobs />} />
            <Route path={'prophylaxe'} exact={true} element={<ServicesSubpage item={'prophylaxe'} />} />
            <Route path={'prothetik'} exact={true} element={<ServicesSubpage item={'prothetik'} />} />
            <Route path={'aesthetische-zahnmedizin'} exact={true} element={<ServicesSubpage item={'aestethischeZahnmedizin'} />} />
            <Route path={'implantologie'} exact={true} element={<ServicesSubpage item={'implantologie'} />} />
            <Route path={'ausstattung'} exact={true} element={<GalleryPage keyword={'equipment'} />} />
            <Route path={'einrichtung'} exact={true} element={<GalleryPage keyword={'office'} />} />
            <Route path={'rundgang'} exact={true} element={<Tour />} />
            <Route path={'impressum'} exact={true} element={<Imprint />} />
            <Route path={'datenschutz'} exact={true} element={<DataProtection />} />
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
