import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Layout from '../../Layout/Layout'
import { StyledGalleryPage, Headline, SliderWrapper, Hint } from './GalleryPage.Styled'
import Slider from '../../SubComponents/Slider/Slider'
import images from '../images'

const GalleryPage = ({ keyword }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout authRequired={false}>
      <StyledGalleryPage>
        <Container>
          <Headline>{translation[keyword].headline}</Headline>
          <SliderWrapper>
            <Slider images={images[keyword]} />
          </SliderWrapper>
          <Hint>{translation[keyword].hint}</Hint>
        </Container>
      </StyledGalleryPage>
    </Layout>
  )
}

export default GalleryPage
