import { Container } from '@think-internet/ui-components'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import Layout from '../Layout/Layout'
import { StyledDataProtection, Headline, SubHeadline, ParagraphHeadline, MinorHeadline, Text } from './DataProtection.Styled'

const DataProtection = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout authRequired={false}>
      <StyledDataProtection>
        <Headline>{translation.dataProtection.headline}</Headline>
        <Container>
          <SubHeadline>1. Datenschutz auf einen Blick</SubHeadline>
          <ParagraphHeadline>Allgemeine Hinweise</ParagraphHeadline>
          <Text>
            Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
            Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
            Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
          </Text>
          <ParagraphHeadline>Datenerfassung auf dieser Website</ParagraphHeadline>{' '}
          <MinorHeadline>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</MinorHeadline>
          <Text>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
            &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser Datenschutzerkl&auml;rung entnehmen.
          </Text>
          <MinorHeadline>Wie erfassen wir Ihre Daten?</MinorHeadline>
          <Text>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </Text>
          <Text>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
            technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie diese Website betreten.
          </Text>
          <MinorHeadline>Wof&uuml;r nutzen wir Ihre Daten?</MinorHeadline>
          <Text>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
            Analyse Ihres Nutzerverhaltens verwendet werden.
          </Text>
          <MinorHeadline>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</MinorHeadline>
          <Text>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen
            Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine
            Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen.
            Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten
            zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
          </Text>
          <Text>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</Text>
          <SubHeadline>2. Hosting und Content Delivery Networks (CDN)</SubHeadline>
          <Text>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</Text>
          <ParagraphHeadline>Amazon Web Services (AWS)</ParagraphHeadline>
          <Text>Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg (nachfolgend AWS).</Text>
          <Text>
            Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS verarbeitet. Hierbei k&ouml;nnen auch
            personenbezogene Daten an das Mutterunternehmen von AWS in die USA &uuml;bermittelt werden. Die Daten&uuml;bertragung in die USA wird auf
            die EU-Standardvertragsklauseln gest&uuml;tzt. Details finden Sie hier:
            <a href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/" target="_blank" rel="noopener noreferrer">
              https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
            </a>
            .
          </Text>
          <Text>
            Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von AWS:
            <a href="https://aws.amazon.com/de/privacy/?nc1=f_pr" target="_blank" rel="noopener noreferrer">
              https://aws.amazon.com/de/privacy/?nc1=f_pr
            </a>
            .
          </Text>
          <Text>
            Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer m&ouml;glichst
            zuverl&auml;ssigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
            Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
            Einwilligung ist jederzeit widerrufbar.
          </Text>
          <ParagraphHeadline>Amazon CloudFront CDN</ParagraphHeadline>
          <Text>
            Wir nutzen das Content Delivery Network Amazon CloudFront CDN. Anbieter ist die Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy,
            L-1855, Luxemburg (nachfolgend &bdquo;Amazon&ldquo;).
          </Text>
          <Text>
            Bei Amazon CloudFront CDN handelt es sich um ein weltweit verteiltes Content Delivery Network. Dabei wird technisch der
            Informationstransfer zwischen Ihrem Browser und unserer Website &uuml;ber das Content Delivery Network geleitet. Hierdurch k&ouml;nnen wir
            die weltweite Erreichbarkeit und die Leistungsf&auml;higkeit unserer Website erh&ouml;hen.
          </Text>
          <Text>
            Der Einsatz von Amazon CloudFront CDN beruht auf unserem berechtigten Interesse an einer m&ouml;glichst fehlerfreien und sicheren
            Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
          </Text>
          <Text>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:
            <a href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/" target="_blank" rel="noopener noreferrer">
              https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
            </a>
            .
          </Text>
          <Text>
            Weitere Informationen zu Amazon CloudFront CDN finden Sie hier:
            <a
              href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf
            </a>
            .
          </Text>
          <SubHeadline>3. Allgemeine Hinweise und Pflicht&shy;informationen</SubHeadline>
          <ParagraphHeadline>Datenschutz</ParagraphHeadline>
          <Text>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.
          </Text>
          <Text>
            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
            pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
            wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
          </Text>
          <Text>
            Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
            aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
          </Text>
          <ParagraphHeadline>Hinweis zur verantwortlichen Stelle</ParagraphHeadline>{' '}
          <Text>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</Text>
          <Text>
            &#91;Voller Namen bzw. die vollst&auml;ndige Unternehmensbezeichnung des Website-Betreibers sowie die vollst&auml;ndige Anschrift&#93;
          </Text>
          <Text>
            Telefon: &#91;Telefonnummer der verantwortlichen Stelle&#93;
            <br />
            E-Mail: &#91;E-Mail-Adresse der verantwortlichen Stelle&#93;
          </Text>
          <Text>
            Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
            Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
          </Text>
          <ParagraphHeadline>Speicherdauer</ParagraphHeadline>
          <Text>
            Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
            Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen
            Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben (z.&nbsp;B. steuer- oder handelsrechtliche Aufbewahrungsfristen);
            im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
          </Text>
          <ParagraphHeadline>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</ParagraphHeadline>
          <Text>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit.
            a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer
            ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung
            au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf
            Informationen in Ihr Endger&auml;t (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung
            zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
            Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage
            des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung
            erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
            Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den
            folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.
          </Text>
          <ParagraphHeadline>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</ParagraphHeadline>
          <Text>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
            Wenn diese Tools aktiv sind, k&ouml;nnen Ihre personenbezogene Daten in diese Drittstaaten &uuml;bertragen und dort verarbeitet werden.
            Wir weisen darauf hin, dass in diesen L&auml;ndern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise
            sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden herauszugeben, ohne dass Sie als Betroffener
            hiergegen gerichtlich vorgehen k&ouml;nnten. Es kann daher nicht ausgeschlossen werden, dass US-Beh&ouml;rden (z.&nbsp;B. Geheimdienste)
            Ihre auf US-Servern befindlichen Daten zu &Uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese
            Verarbeitungst&auml;tigkeiten keinen Einfluss.
          </Text>
          <ParagraphHeadline>Widerruf Ihrer Einwilligung zur Datenverarbeitung</ParagraphHeadline>
          <Text>
            Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits
            erteilte Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
            Widerruf unber&uuml;hrt.
          </Text>
          <ParagraphHeadline>
            Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
          </ParagraphHeadline>
          <Text>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT
            AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
            MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE
            INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON
            RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          </Text>
          <Text>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
            MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
          </Text>
          <ParagraphHeadline>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</ParagraphHeadline>
          <Text>
            Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere
            in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu.
            Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
          </Text>
          <ParagraphHeadline>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</ParagraphHeadline>
          <Text>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
            sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
            &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </Text>
          <ParagraphHeadline>Auskunft, L&ouml;schung und Berichtigung</ParagraphHeadline>
          <Text>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
            personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
            L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
          </Text>
          <ParagraphHeadline>Recht auf Einschr&auml;nkung der Verarbeitung</ParagraphHeadline>
          <Text>
            Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
            jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:
          </Text>
          <ul>
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies
              zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung
              die Einschr&auml;nkung der Datenverarbeitung verlangen.
            </li>
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
              Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <Text>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung
            abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum
            Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen
            Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.
          </Text>
          <ParagraphHeadline>SSL- bzw. TLS-Verschl&uuml;sselung</ParagraphHeadline>
          <Text>
            Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
            Schloss-Symbol in Ihrer Browserzeile.
          </Text>
          <Text>
            Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
            mitgelesen werden.
          </Text>
          <ParagraphHeadline>Widerspruch gegen Werbe-E-Mails</ParagraphHeadline>
          <Text>
            Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
            angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich
            rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
          </Text>
          <SubHeadline>4. Datenerfassung auf dieser Website</SubHeadline>
          <ParagraphHeadline>Kontaktformular</ParagraphHeadline>
          <Text>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
            wir nicht ohne Ihre Einwilligung weiter.
          </Text>
          <Text>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
            Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen
            F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art.
            6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist
            jederzeit widerrufbar.
          </Text>
          <Text>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
            Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
          </Text>
          <ParagraphHeadline>Anfrage per E-Mail, Telefon oder Telefax</ParagraphHeadline>
          <Text>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </Text>
          <Text>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
            Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen
            F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art.
            6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist
            jederzeit widerrufbar.
          </Text>
          <Text>
            Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
            Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
            unber&uuml;hrt.
          </Text>
          <SubHeadline>5. Eigene Dienste</SubHeadline>
          <ParagraphHeadline>Umgang mit Bewerberdaten</ParagraphHeadline>
          <Text>
            Wir bieten Ihnen die M&ouml;glichkeit, sich bei uns zu bewerben (z.&nbsp;B. per E-Mail, postalisch oder via Online-Bewerberformular). Im
            Folgenden informieren wir Sie &uuml;ber Umfang, Zweck und Verwendung Ihrer im Rahmen des Bewerbungsprozesses erhobenen personenbezogenen
            Daten. Wir versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer Daten in &Uuml;bereinstimmung mit geltendem Datenschutzrecht und
            allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt werden.
          </Text>
          <MinorHeadline>Umfang und Zweck der Datenerhebung </MinorHeadline>
          <Text>
            Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit verbundenen personenbezogenen Daten (z.&nbsp;B. Kontakt- und
            Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von Bewerbungsgespr&auml;chen etc.), soweit dies zur Entscheidung &uuml;ber
            die Begr&uuml;ndung eines Besch&auml;ftigungsverh&auml;ltnisses erforderlich ist. Rechtsgrundlage hierf&uuml;r ist &sect; 26 BDSG nach
            deutschem Recht (Anbahnung eines Besch&auml;ftigungsverh&auml;ltnisses), Art. 6 Abs. 1 lit. b DSGVO (allgemeine Vertragsanbahnung) und
            &ndash; sofern Sie eine Einwilligung erteilt haben &ndash; Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist jederzeit widerrufbar. Ihre
            personenbezogenen Daten werden innerhalb unseres Unternehmens ausschlie&szlig;lich an Personen weitergegeben, die an der Bearbeitung Ihrer
            Bewerbung beteiligt sind.
          </Text>
          <Text>
            Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten Daten auf Grundlage von &sect; 26 BDSG und Art. 6 Abs. 1 lit. b
            DSGVO zum Zwecke der Durchf&uuml;hrung des Besch&auml;ftigungsverh&auml;ltnisses in unseren Datenverarbeitungssystemen gespeichert.
          </Text>
          <MinorHeadline>Aufbewahrungsdauer der Daten</MinorHeadline>
          <Text>
            Sofern wir Ihnen kein Stellenangebot machen k&ouml;nnen, Sie ein Stellenangebot ablehnen oder Ihre Bewerbung zur&uuml;ckziehen, behalten
            wir uns das Recht vor, die von Ihnen &uuml;bermittelten Daten auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO)
            bis zu 6 Monate ab der Beendigung des Bewerbungsverfahrens (Ablehnung oder Zur&uuml;ckziehung der Bewerbung) bei uns aufzubewahren.
            Anschlie&szlig;end werden die Daten gel&ouml;scht und die physischen Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient insbesondere
            Nachweiszwecken im Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach Ablauf der 6-Monatsfrist erforderlich sein
            werden (z.&nbsp;B. aufgrund eines drohenden oder anh&auml;ngigen Rechtsstreits), findet eine L&ouml;schung erst statt, wenn der Zweck
            f&uuml;r die weitergehende Aufbewahrung entf&auml;llt.
          </Text>
          <Text>
            Eine l&auml;ngere Aufbewahrung kann au&szlig;erdem stattfinden, wenn Sie eine entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
            erteilt haben oder wenn gesetzliche Aufbewahrungspflichten der L&ouml;schung entgegenstehen.
          </Text>
          <MinorHeadline>Aufnahme in den Bewerber-Pool</MinorHeadline>
          <Text>
            Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die M&ouml;glichkeit, Sie in unseren Bewerber-Pool aufzunehmen. Im Falle der
            Aufnahme werden alle Dokumente und Angaben aus der Bewerbung in den Bewerber-Pool &uuml;bernommen, um Sie im Falle von passenden Vakanzen
            zu kontaktieren.
          </Text>
          <Text>
            Die Aufnahme in den Bewerber-Pool geschieht ausschlie&szlig;lich auf Grundlage Ihrer ausdr&uuml;cklichen Einwilligung (Art. 6 Abs. 1 lit.
            a DSGVO). Die Abgabe der Einwilligung ist freiwillig und steht in keinem Bezug zum laufenden Bewerbungsverfahren.
            <strong>Der Betroffene kann seine Einwilligung jederzeit widerrufen.</strong> In diesem Falle werden die Daten aus dem Bewerber-Pool
            unwiderruflich gel&ouml;scht, sofern keine gesetzlichen Aufbewahrungsgr&uuml;nde vorliegen.
          </Text>
          <Text>Die Daten aus dem Bewerber-Pool werden sp&auml;testens zwei Jahre nach Erteilung der Einwilligung unwiderruflich gel&ouml;scht.</Text>
        </Container>
      </StyledDataProtection>
    </Layout>
  )
}

export default DataProtection
