import { useSelector } from 'react-redux'
import { StyledFooter, Column, Flex, Header, ColumnContent, MobileWave, LineSplit, StyledLink, IconWrapper, Content } from './Footer.Styled'
import props from '../../../redux/props'
import Icon from '../../SubComponents/Icon/Icon'
import { getUUID } from '../../../utility'

const Link = ({ href, children, newPage = false }) => {
  return (
    <StyledLink href={href} target={newPage ? '_blank' : ''}>
      {children}
    </StyledLink>
  )
}

const Footer = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledFooter>
      <MobileWave reverse />
      <Content>
        <Flex>
          <Column>
            <Header>{translation.footer.aboutUs.title}</Header>
            <ColumnContent>
              {translation.footer.aboutUs.text.map((t) => (
                <div key={getUUID()}>{t}</div>
              ))}
            </ColumnContent>
          </Column>
          <Column>
            <Header>{translation.footer.contact}</Header>
            <ColumnContent>
              <LineSplit>
                <IconWrapper>
                  <Icon width={'25px'} height={'25px'} name={'location'} />
                </IconWrapper>
                <Link href={translation.genericValues.locationLink} newPage>
                  <div>{translation.genericValues.name}</div>
                  <div>{translation.genericValues.street}</div>
                  <div>{translation.genericValues.city}</div>
                </Link>
              </LineSplit>
              <LineSplit>
                <IconWrapper>
                  <Icon width={'25px'} height={'25px'} name={'phone'} />
                </IconWrapper>
                <Link href={`tel:${translation.genericValues.phone}`}>{translation.genericValues.phone}</Link>
              </LineSplit>
              <LineSplit>
                <IconWrapper>
                  <Icon width={'25px'} height={'25px'} name={'mail'} />
                </IconWrapper>
                <Link href={`mailto:${translation.genericValues.mail}`}>{translation.genericValues.mail}</Link>
              </LineSplit>
            </ColumnContent>
          </Column>
          <Column>
            <Header>{translation.footer.pages.title}</Header>
            <ColumnContent>
              {Object.keys(translation.header.menu).map((key) => (
                <LineSplit key={key} align={'flex-bottom'}>
                  <Link href={translation.header.menu[key].href}>{translation.header.menu[key].name}</Link>
                  <IconWrapper>
                    <Icon width={'15px'} name={'arrow-to-right-blue'} />
                  </IconWrapper>
                </LineSplit>
              ))}
            </ColumnContent>
          </Column>
          <Column>
            <Header hideOnMobile={true}>&nbsp;</Header>
            <ColumnContent>
              <LineSplit>
                <IconWrapper>
                  <Icon height={'25px'} name={'instagram'} />
                </IconWrapper>
                <Link newPage href={translation.genericValues.instagramLink}>
                  {translation.genericValues.instagramHandle}
                </Link>
              </LineSplit>
              <LineSplit>
                <IconWrapper>
                  <Icon height={'25px'} name={'facebook'} />
                </IconWrapper>
                <Link newPage href={translation.genericValues.facebookLink}>
                  {translation.genericValues.facebookHandle}
                </Link>
              </LineSplit>
            </ColumnContent>
          </Column>
        </Flex>
      </Content>
    </StyledFooter>
  )
}

export default Footer
