import * as operation from "./operation";
import defaultState from "./defaultState";

const getPayload = (state, action, replacePayload) => {
  if (replacePayload) return action.payload;
  else if (Array.isArray(state[action.type]))
    return [action.payload, ...state[action.type]];
  else if (Array.isArray(action.payload)) return action.payload;
  return [action.payload];
};

const reducer = (state = defaultState, action) => {
  if (!action.operation) return state;
  if (action.operation === operation.DELETE) {
    const newState = { ...state };
    delete newState[action.type];
    return newState;
  }
  const replacePayload = action.replace !== undefined ? action.replace : true;

  return {
    ...state,
    [action.type]: getPayload(state, action, replacePayload),
  };
};

export default reducer;
