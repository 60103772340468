import { StyledBackgroundWave, Spacer } from './BackgroundWave.Styled'
import Wave from '../Wave/Wave'

const BackgroundWave = ({ height, reverse = false }) => {
  return (
    <StyledBackgroundWave reverse={reverse}>
      {!reverse && <Spacer height={height} />}
      <Wave reverse={reverse} />
      {reverse && <Spacer height={height} />}
    </StyledBackgroundWave>
  )
}

export default BackgroundWave
