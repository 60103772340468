import { Container } from '@think-internet/ui-components'
import styled from 'styled-components'

export const StyledJobs = styled.div`
  padding: 75px 0px;
  z-index: 1;
  position: relative;
`

export const CustomContainer = styled(Container)`
  z-index: 1;
  position: relative;
`

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xxlg};
  color: ${({ theme }) => theme.color.font.secondary};
  z-index: 1;
  position: relative;
  text-align: center;
`

export const TitleWrapper = styled.div`
  text-align: center;
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.fifth};
  background: rgb(50, 87, 154);
  background: linear-gradient(196deg, rgba(50, 87, 154, 1) 50%, rgba(103, 173, 204, 1) 100%);
  padding: 20px 40px;
  border-radius: 50px;
  display: inline-block;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`

export const AboutText = styled.div`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`
export const Split = styled.div`
  display: flex;
  gap: 50px;
  justify-items: center;
  margin: 50px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`
export const Page = styled.div`
  width: 100%;
`
export const Buttetpoints = styled.div`
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 0px;
  }
`
export const BulletpointsTitle = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.fifth};
`
export const Points = styled.div``
export const Point = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
`

export const OfferingImageSectionHeadline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.fifth};
  margin-top: 50px;
`
export const OfferingImageSection = styled.div`
  display: flex;
  gap: 50px;
  justify-items: center;
  margin-top: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`
export const OfferingImageItem = styled.div`
  width: 100%;
`

export const OfferingImageItemImage = styled.div`
  width: 100%;
  height: 250px;
  background-image: url(${({ image }) => image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
`
export const OfferingImageItemText = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.fifth};
  margin-top: 25px;
`
