import styled from 'styled-components'

export const StyledSlider = styled.div``

export const Arrow = styled.div`
  position: absolute;
  ${({ direction }) => (direction === 'prev' ? 'left: 0px' : 'right: 0px')};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  cursor: pointer;
  z-index: 1;
`

export const Icon = styled.img`
  width: 50px !important;
  margin-top: -75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-top: -50px;
    width: 30px !important;
  }
`

export const Indicator = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.color.background.hint : theme.color.background.primary)};
  border: 3px solid ${({ theme }) => theme.color.background.hint};
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.color.background.hint};
  }
`
